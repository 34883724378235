.App {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  max-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

a:visited {
  color: inherit;
}

.display-none {
  display: none !important;
}

.video-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .plyr {
    margin-top: -4px;
    height: calc(100vh + 8px);
    width: calc(100vh / 9 * 16);
    margin-left: calc((100vh / 9 * 16 - 100vw) / -2);
    max-width: unset;
  }

  .plyr__video-embed iframe {
    top: -50%;
    height: 200%;
  }
}

.grey-layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: saturate(160%) contrast(110%) brightness(104%);
}

.big-title {
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  mix-blend-mode: difference;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 8rem;
  font-weight: 700;

  span {
    cursor: pointer;
  }
}

.big-play-button {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #fff;
  font-size: 8rem;
  user-select: none;

  span {
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 4px;
  }
}

.info {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  mix-blend-mode: difference;
  color: #fff;

  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 32px 0 32px;
    font-weight: 700;

    .website-title {
      cursor: pointer;
      font-size: 64px;
    }

    .credit {
      font-size: 18px;
      margin-right: 18px;
    }

    .playlist-details {
      margin-top: -4px;
      display: none;

      .title {
        font-size: 2.2rem;
      }

      .dj {
        margin-top: 3px;
        font-size: 1.4rem;
      }
    }
  }

  .bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 32px 32px 32px;
    font-weight: 700;

    .playlist-details {
      .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 8px;
        margin-left: 4px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .dj {
        font-size: 18px;
        margin-left: 4px;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      font-size: 24px;
      cursor: pointer;
      user-select: none;
      div {
        transition: all 0.4s;
        &:hover {
          letter-spacing: 2px;
        }
      }
      .pause {
        margin-right: 48px;
      }

      .menu {
        margin-right: 24px;
      }
    }
  }
}

.menu-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 256px;
  max-width: 36vw;
  height: 100vh;
  border-left: 2px solid rgba(255, 255, 255, 0.06);
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(100%);
  transition: all 0.4s;

  .playlists {
    overflow: scroll;
    padding-bottom: 1rem;

    .playlist {
      margin: 2rem 1.4rem 1rem 1.4rem;
      padding-left: 12px;
      border-left: 8px solid rgba(255, 255, 255, 0.16);
      cursor: pointer;
      transition: all 0.2s;

      .title {
        font-size: 1.4rem;
        font-weight: 700;
      }

      .dj {
        opacity: 0.58;
        font-size: 1.2rem;
        margin-top: 2px;
      }

      &:hover {
        border-color: rgba(255, 255, 255, 1);
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .buttons {
    font-weight: 700;
    font-size: 1.4rem;
    border-top: 2px solid rgba(255, 255, 255, 0.06);
    display: flex;
    line-height: 28px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-right: 2px solid rgba(255, 255, 255, 0.06);
      padding: 1.4rem 0;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        letter-spacing: 2px;
      }
    }
  }
}

.menu-panel.show {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .big-title {
    font-size: 4rem;
    text-align: center;
  }

  .info {
    .top {
      flex-direction: column;
      align-items: flex-start;

      .website-title {
        cursor: default;
        font-size: 3rem;
      }

      .credit {
        margin-top: 6px;
        font-size: 0.6rem;
        opacity: 0.28;
      }

      .playlist-details {
        display: block;
      }
    }

    .bottom {
      flex-direction: column;

      .title {
        font-size: 1.4rem;
        display: none;
      }

      .dj {
        font-size: 1rem;
        display: none;
      }

      .controls {
        margin-bottom: 24px;
        font-size: 1.2rem;
        justify-content: flex-start;
        text-align: center;
        width: 100%;

        .pause {
          margin-right: 0;
          flex: 1;
        }

        .menu {
          flex: 1;
          margin-right: 0;
        }
      }
    }
  }

  .big-play-button {
    font-size: 6rem;
  }

  .menu-panel {
    max-width: 100vw;
    left: 0;
  }
}

@media (max-width: 414px) {
  .info .top {
    .website-title {
      font-size: 2.9rem;
    }

    .credit {
      font-size: 0.6rem;
    }

    .playlist-details {
      .title {
        font-size: 2.2rem;
      }

      .dj {
        font-size: 1.4rem;
      }
    }
  }

  .big-play-button {
    font-size: 4rem;
  }
}

@media (max-width: 375px) {
  .info .top {
    .website-title {
      font-size: 2.6rem;
    }

    .playlist-details {
      .title {
        font-size: 1.8rem;
      }

      .dj {
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 360px) {
  .info .top .website-title {
    font-size: 2.1rem;
  }
}

// glitch animation created by https://codepen.io/noureddinemade/details/bGdKRRp
.glitch-animation {
  .glitch-container {
    margin: auto;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      mix-blend-mode: screen;
    }

    &:before,
    &:after {
      content: attr(data-name);
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:before {
      left: -5%;
      color: #ff0000;
      clip: rect(0, 1350px, 140px, 0);
      animation-name: glitchRed;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    &:after {
      left: 5%;
      color: #0000ff;
      clip: rect(0, 1350px, 50px, 0);
      animation-name: glitchBlue;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    span {
      position: relative;
      z-index: 1;

      color: white;

      clip: rect(0, 900px, 722px, 56px);

      animation-name: glitchGreen;
      animation-duration: 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes glitchRed {
  0% {
    left: 0;

    clip-path: inset(0% 0 0% 0);
  }

  9% {
    left: 0;

    clip-path: inset(0% 0 0% 0);
  }

  10% {
    left: 1%;

    clip-path: inset(21% 0 5% 0);
  }

  19% {
    left: 1%;

    clip-path: inset(21% 0 5% 0);
  }

  20% {
    left: 6%;

    clip-path: inset(72% 0 55% 0);
  }

  29% {
    left: 6%;

    clip-path: inset(72% 0 55% 0);
  }

  30% {
    left: 0;

    clip-path: inset(12% 0 -52% 0);
  }

  39% {
    left: 0;

    clip-path: inset(12% 0 -52% 0);
  }

  40% {
    left: -5%;

    clip-path: inset(-80% 0 12% 0);
  }

  49% {
    left: -5%;

    clip-path: inset(-80% 0 12% 0);
  }

  50% {
    left: 0;

    clip-path: inset(95% 0 32% 0);
  }

  59% {
    left: 0;

    clip-path: inset(95% 0 32% 0);
  }

  60% {
    left: 2%;

    clip-path: inset(4% 0 27% 0);
  }

  69% {
    left: 2%;

    clip-path: inset(4% 0 27% 0);
  }

  70% {
    left: 0;

    clip-path: inset(-64% 0 -77% 0);
  }

  79% {
    left: 0;

    clip-path: inset(-64% 0 -77% 0);
  }

  80% {
    left: -8%;

    clip-path: inset(75% 0 -4% 0);
  }

  89% {
    left: -8%;
    clip-path: inset(75% 0 -4% 0);
  }

  90% {
    left: 0;
    clip-path: inset(-33% 0 96% 0);
  }

  99% {
    left: 0;

    clip-path: inset(-33% 0 96% 0);
  }

  100% {
    left: 3%;
    clip-path: inset(48% 0 50% 0);
  }
}

@keyframes glitchBlue {
  0% {
    left: 0;

    clip-path: inset(0% 0 0% 0);
  }

  9% {
    left: 0;

    clip-path: inset(0% 0 0% 0);
  }

  10% {
    left: -2%;

    clip-path: inset(41% 0 -15% 0);
  }

  19% {
    left: -2%;

    clip-path: inset(41% 0 -15% 0);
  }

  20% {
    left: -4%;

    clip-path: inset(-72% 0 75% 0);
  }

  29% {
    left: -4%;

    clip-path: inset(-72% 0 75% 0);
  }

  30% {
    left: 0;

    clip-path: inset(62% 0 4% 0);
  }

  39% {
    left: 0;
    clip-path: inset(62% 0 4% 0);
  }

  40% {
    left: 7%;
    clip-path: inset(80% 0 -12% 0);
  }

  49% {
    left: 7%;
    clip-path: inset(80% 0 -12% 0);
  }

  50% {
    left: 0;
    clip-path: inset(5% 0 55% 0);
  }

  59% {
    left: 0;
    clip-path: inset(5% 0 55% 0);
  }

  60% {
    left: -5%;
    clip-path: inset(-47% 0 36% 0);
  }

  69% {
    left: -5%;
    clip-path: inset(-47% 0 36% 0);
  }

  70% {
    left: 0;
    clip-path: inset(24% 0 -7% 0);
  }

  79% {
    left: 0;
    clip-path: inset(24% 0 -7% 0);
  }

  80% {
    left: 5%;
    clip-path: inset(-75% 0 4% 0);
  }

  89% {
    left: 5%;
    clip-path: inset(-75% 0 4% 0);
  }

  90% {
    left: 0;
    clip-path: inset(3% 0 -96% 0);
  }

  99% {
    left: 0;
    clip-path: inset(3% 0 -96% 0);
  }

  100% {
    left: -3%;
    clip-path: inset(98% 0 -50% 0);
  }
}

@keyframes glitchGreen {
  0% {
    clip-path: inset(0% 0 0% 0);
  }

  9% {
    clip-path: inset(0% 0 0% 0);
  }

  10% {
    clip-path: inset(41% 0 -15% 0);
  }

  19% {
    clip-path: inset(41% 0 -15% 0);
  }

  20% {
    clip-path: inset(-72% 0 75% 0);
  }

  29% {
    clip-path: inset(-72% 0 75% 0);
  }

  30% {
    clip-path: inset(62% 0 4% 0);
  }

  39% {
    clip-path: inset(62% 0 4% 0);
  }

  40% {
    clip-path: inset(80% 0 -12% 0);
  }

  49% {
    clip-path: inset(80% 0 -12% 0);
  }

  50% {
    clip-path: inset(5% 0 55% 0);
  }

  59% {
    clip-path: inset(5% 0 55% 0);
  }

  60% {
    clip-path: inset(-47% 0 36% 0);
  }

  69% {
    clip-path: inset(-47% 0 36% 0);
  }

  70% {
    clip-path: inset(24% 0 -7% 0);
  }

  79% {
    clip-path: inset(24% 0 -7% 0);
  }

  80% {
    clip-path: inset(-75% 0 4% 0);
  }

  89% {
    clip-path: inset(-75% 0 4% 0);
  }

  90% {
    clip-path: inset(3% 0 -96% 0);
  }

  99% {
    clip-path: inset(3% 0 -96% 0);
  }

  100% {
    clip-path: inset(98% 0 -50% 0);
  }
}
