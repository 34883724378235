@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700;800;900&display=swap");
@import url("https://cdn.plyr.io/3.6.8/plyr.css");

body {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  margin: 0;
  font-weight: 600;
}
